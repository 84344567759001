<template>
  <div class="text-primary text-3xl text-center py-7 border-b border-grey-fp-30 mb-10">Tables</div>
  <div class="flex justify-center">
    <AppTabs v-model="activeTab" :items="tabs" class="mb-40" />
  </div>
  
  <!-- F A M I L Y -->
  <div v-if="activeTab.value === 'familyPortal'">
    <AppTableCards 
      :dataset="dataset1" 
      :headers="headers1" 
      class="md:hidden mobile-table"
    > 
      <!-- T I T L E -->
      <template #tableTitle>
        <p class="text-md xs:text-1xl text-warning px-18">Table Title</p>
      </template>

      <!-- R O W S -->
      <template #startDate="{ row }">
        <div class="flex flex-col">
          <span>
            {{ $filters.date(row.startDate, 'MM/DD/YYYY') }}  
          </span> 
          <span class="text-grey-fp-50 text-xs">
            {{ $filters.date(row.startDate, 'h:mm a') }}
          </span>
        </div>
      </template>

      <template #endDate="{ row }">
        <div class="flex flex-col">
          <span>
            {{ $filters.date(row.startDate, 'MM/DD/YYYY') }}  
          </span> 
          <span class="text-grey-fp-50 text-xs">
            {{ $filters.date(row.startDate, 'h:mm a') }}
          </span>
        </div>
      </template>

      <template #deadline="{ row }">
        <div class="flex flex-col">
          <span>
            {{ $filters.date(row.startDate, 'MM/DD/YYYY') }}  
          </span> 
          <span class="text-grey-fp-50 text-xs">
            {{ $filters.date(row.startDate, 'h:mm a') }}
          </span>
        </div>
      </template>

      <template #registered="{ row }">
        <div class="flex">
          <AppToggle v-model="row.registered" />
        </div>
      </template>

      <template #actions="{ row }">
        <a :href="row.registrationLink" target="_blank">
          <AppButton type="warning" size="mini" class="px-20"> Register </AppButton>
        </a>
      </template>
    </AppTableCards>

    <div class=" hidden md:flex">
      <AppTable
        v-model:dataset="dataset1"
        :headers="headers1"
        class="table-height-preview"
        rowHeight="50px"
        sort-front-side
        @rowClick="rowClick"
      >
        <!-- T I T L E -->
        <template #tableTitle>
          <p class="text-md xs:text-1xl text-warning px-18">Table Title</p>
        </template>

        <!-- R O W S -->
        <template #startDate="{ row }">
          <div class="flex flex-col">
            <p>
              {{ $filters.date(row.startDate, 'MM/DD/YYYY') }}  
            </p> 
            <p class="text-grey-fp-50 text-xs">
              {{ $filters.date(row.startDate, 'h:mm a') }}
            </p>
          </div>
        </template>

        <template #endDate="{ row }">
          <div class="flex flex-col">
            <p>
              {{ $filters.date(row.startDate, 'MM/DD/YYYY') }}  
            </p> 
            <p class="text-grey-fp-50 text-xs">
              {{ $filters.date(row.startDate, 'h:mm a') }}
            </p>
          </div>
        </template>

        <template #deadline="{ row }">
          <div class="flex flex-col">
            <p>
              {{ $filters.date(row.startDate, 'MM/DD/YYYY') }}  
            </p> 
            <p class="text-grey-fp-50 text-xs">
              {{ $filters.date(row.startDate, 'h:mm a') }}
            </p>
          </div>
        </template>

        <template #registered="{ row }">
          <div class="flex">
            <AppToggle v-model="row.registered" />
          </div>
        </template>

        <template #registrationLink="{ row }">
          <a :href="row.registrationLink" target="_blank">
            <AppButton type="warning" size="mini" class="px-20"> Register </AppButton>
          </a>
        </template>
      </AppTable>
    </div>
  </div>

  <!-- A D M I N -->
  <div v-else>
    <div class="flex">
      <AppTabs v-model="activeTableType" :items="tabelTypes" class="mb-40" />
    </div>

    <AppTable
      v-model:dataset="dataset1"
      :headers="headers2"
      class="table-height-preview"
      rowHeight="50px"
      type="admin"
      :theme="activeTableType.value"
      sort-front-side
      show-actions
      @rowClick="rowClick"
    >
      <!-- R O W S -->
      <template #startDate="{ row }">
        {{ $filters.date(row.startDate, 'MMM YYYY') }}
      </template>

      <template #endDate="{ row }">
        {{ $filters.date(row.endDate, 'MMM YYYY') }}
      </template>

      <template #deadline="{ row }">
        {{ $filters.date(row.deadline, 'MMM YYYY') }}
      </template>

      <!-- A C T I O N S -->
      <template #actions="{ row }">
        <ul class="actions-wrapper py-10">
          <li 
            v-for="(action, i) in actions"
            :key="i"
            class="action flex items-center cursor-pointer py-7 px-10" 
            :class="action.customClass"
            @click="action.handler(row)"
          >
            <AppIcon :name="action.icon" size="12" class="mr-10" />
            <span>{{ action.title }}</span>
          </li>
        </ul>
      </template>
    </AppTable>
    <div class="h-190" />
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, reactive, toRefs, computed } from 'vue';
  import { useScreen } from 'vue-screen';

  import { ITableSort, IOption } from '@/types';

  import AppTable from '@/components/stateless/AppTable.vue';
  import AppToggle from '@/components/stateless/AppToggle.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppTabs from '@/components/stateless/AppTabs.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppTableCards from '@/components/AppTableCards.vue';

  export default defineComponent({
    name: 'Tables',

    components: { AppTable, AppToggle, AppButton, AppTabs, AppIcon, AppTableCards },

    setup() {
      const isPrimary = ref<boolean>(false);
      const screen = useScreen();
      const headers1 = computed(() => [
        { property: 'firstName', label: 'First Name', minWidth: 200 },
        { property: 'description', label: 'Description', minWidth: 280, cellClasses: 'text-grey-fp-50' },
        { property: 'startDate', label: 'Start Date', minWidth: 140 },
        { property: 'endDate', label: 'End Date', minWidth: 130 },
        { property: 'deadline', label: 'Sign-up Deadline', minWidth: 200 },
        { property: 'registered', label: 'RSVP', minWidth: 80 },
        { property: 'registrationLink', minWidth: 180, show: screen.width > 768 },
      ]);
      const dataset1 = ref([
        {
          firstName: 'Mercedes',
          description: 'small descriptions long long long long long long long long long long text long long long long long long long long long text long long long long long long long long long text long long long long long long long long long text long long long long long long long long long text long long long long long long long long long text long long long long long long long long long text long long long long long long long long long text long long long long long long long long long text long long long long long long long long long text',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        }, 
        {
          firstName: 'James',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'Thomas',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'Matthew',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },

      ]);

      const headers2 = [
        { property: 'firstName', label: 'Activity Name', sortable: true, minWidth: 200 },
        { property: 'description', label: 'Description', sortable: true, minWidth: 280 },
        { property: 'startDate', label: 'Start Date', sortable: true, minWidth: 140 },
        { property: 'endDate', label: 'End Date', sortable: true, minWidth: 130 },
        { property: 'deadline', label: 'Sign-up Deadline', sortable: true, minWidth: 200 },
      ];

      const tabs: IOption[] = [
        { value: 'familyPortal', label: 'Family Portal'},
        { value: 'adminPortal', label: 'Admin Portal'}
      ];

      const tablePortalTypes = reactive({ activeTableType: { value: 'primary', label: 'Primary' }});
      const { activeTableType } = toRefs(tablePortalTypes);

      const tabelTypes: IOption[] = [
        { value: 'primary', label: 'Primary'},
        { value: 'blue', label: 'Blue'},
        { value: 'orange', label: 'Orange'},
        { value: 'green', label: 'Green'}
      ];

      const tablePortal = reactive({ activeTab: { value: 'familyPortal', label: 'Family Portal' }});
      const { activeTab } = toRefs(tablePortal);

      const actions = ref([
        { title: 'Edit', icon: 'pen', handler: handleAction, customClass: ' text-dark-cl-20' },
        { title: 'Archive', icon: 'archive', handler: handleAction, customClass: ' text-dark-cl-20' },
        { title: 'Remove', icon: 'trash-alt', handler: handleAction, customClass: ' text-error' },
      ]);

      function onUpdateList(e: ITableSort) {
        console.log('onUpdateList: ', `{ orderBy: ${e.orderBy}, order: ${e.order} }`);
      }

      function rowClick(row: any) {
        console.log('row: ', row);
      }

      function handleAction(row: any) {
        console.log('row action',row);
      }

      return {
        dataset1,
        headers1,
        headers2,

        tabs,
        activeTab,
        isPrimary,

        tabelTypes,
        activeTableType,

        onUpdateList,
        actions,
        rowClick,
      };
    }

});
</script>

<style lang="scss" scoped>
.table-height-preview {
  height: 500px;
}
.mobile-table {
  height: 700px;
}
.actions-wrapper {
  .action {
    &:hover {
      @apply opacity-80;
    }
  }
}
</style>
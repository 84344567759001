
  import { defineComponent, ref, reactive, toRefs, computed } from 'vue';
  import { useScreen } from 'vue-screen';

  import { ITableSort, IOption } from '@/types';

  import AppTable from '@/components/stateless/AppTable.vue';
  import AppToggle from '@/components/stateless/AppToggle.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppTabs from '@/components/stateless/AppTabs.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppTableCards from '@/components/AppTableCards.vue';

  export default defineComponent({
    name: 'Tables',

    components: { AppTable, AppToggle, AppButton, AppTabs, AppIcon, AppTableCards },

    setup() {
      const isPrimary = ref<boolean>(false);
      const screen = useScreen();
      const headers1 = computed(() => [
        { property: 'firstName', label: 'First Name', minWidth: 200 },
        { property: 'description', label: 'Description', minWidth: 280, cellClasses: 'text-grey-fp-50' },
        { property: 'startDate', label: 'Start Date', minWidth: 140 },
        { property: 'endDate', label: 'End Date', minWidth: 130 },
        { property: 'deadline', label: 'Sign-up Deadline', minWidth: 200 },
        { property: 'registered', label: 'RSVP', minWidth: 80 },
        { property: 'registrationLink', minWidth: 180, show: screen.width > 768 },
      ]);
      const dataset1 = ref([
        {
          firstName: 'Mercedes',
          description: 'small descriptions long long long long long long long long long long text long long long long long long long long long text long long long long long long long long long text long long long long long long long long long text long long long long long long long long long text long long long long long long long long long text long long long long long long long long long text long long long long long long long long long text long long long long long long long long long text long long long long long long long long long text',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        }, 
        {
          firstName: 'James',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'Thomas',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'Matthew',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },
        {
          firstName: 'John',
          description: 'small descriptions',
          startDate: '2021-05-31T21:00:00.000Z',
          endDate: '2021-05-31T21:00:00.000Z',
          deadline: '2021-05-31T21:00:00.000Z',
          registered: false,
          registrationLink: 'https://www.google.com'
        },

      ]);

      const headers2 = [
        { property: 'firstName', label: 'Activity Name', sortable: true, minWidth: 200 },
        { property: 'description', label: 'Description', sortable: true, minWidth: 280 },
        { property: 'startDate', label: 'Start Date', sortable: true, minWidth: 140 },
        { property: 'endDate', label: 'End Date', sortable: true, minWidth: 130 },
        { property: 'deadline', label: 'Sign-up Deadline', sortable: true, minWidth: 200 },
      ];

      const tabs: IOption[] = [
        { value: 'familyPortal', label: 'Family Portal'},
        { value: 'adminPortal', label: 'Admin Portal'}
      ];

      const tablePortalTypes = reactive({ activeTableType: { value: 'primary', label: 'Primary' }});
      const { activeTableType } = toRefs(tablePortalTypes);

      const tabelTypes: IOption[] = [
        { value: 'primary', label: 'Primary'},
        { value: 'blue', label: 'Blue'},
        { value: 'orange', label: 'Orange'},
        { value: 'green', label: 'Green'}
      ];

      const tablePortal = reactive({ activeTab: { value: 'familyPortal', label: 'Family Portal' }});
      const { activeTab } = toRefs(tablePortal);

      const actions = ref([
        { title: 'Edit', icon: 'pen', handler: handleAction, customClass: ' text-dark-cl-20' },
        { title: 'Archive', icon: 'archive', handler: handleAction, customClass: ' text-dark-cl-20' },
        { title: 'Remove', icon: 'trash-alt', handler: handleAction, customClass: ' text-error' },
      ]);

      function onUpdateList(e: ITableSort) {
        console.log('onUpdateList: ', `{ orderBy: ${e.orderBy}, order: ${e.order} }`);
      }

      function rowClick(row: any) {
        console.log('row: ', row);
      }

      function handleAction(row: any) {
        console.log('row action',row);
      }

      return {
        dataset1,
        headers1,
        headers2,

        tabs,
        activeTab,
        isPrimary,

        tabelTypes,
        activeTableType,

        onUpdateList,
        actions,
        rowClick,
      };
    }

});
